export const removeDiacritics = (str: string) => {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
}

export const toUpperCamelCase = (str: string) => {
    const chars: number[] = new Array(str.length);
    chars[0] = str[0].toUpperCase().charCodeAt(0);
    for(let i = 1; i < str.length; i++) {
        chars[i] = str.charCodeAt(i);
    }
    return String.fromCharCode.apply(undefined, chars);
}

