import {memoizeWith} from "ramda";

import {publicUrl} from "../env-vars";

const ensureProperBasePath = (str: string) => {
    if (str.length === 0 || str[0] !== "/") {
        str = "/" + str;
    }
    if (str.length > 1 && str[str.length - 1] !== "/") {
        str += "/";
    }
    return str;
};

export const getBasePath = memoizeWith(
    () => "baseUrl",
    () => {
        const htmlBase = document.getElementsByTagName("base")[0]?.getAttribute("href");
        if (htmlBase) {
            return ensureProperBasePath(htmlBase);
        }
        if (publicUrl) {
            return ensureProperBasePath(publicUrl);
        }
        return "/";
    }
);
