import {AnySchemaObject, FuncKeywordDefinition, SchemaObjCxt} from "ajv";
import {AddedKeywordDefinition, DataValidateFunction, ErrorObject} from "ajv/dist/types";
import ucs2length from "ajv/dist/runtime/ucs2length";
import {equals, groupBy, mapObjIndexed} from "ramda";
import {checkStrictMode} from "ajv/dist/compile/util";

import {compileNfExpr} from "../exprs";
import {toUpperCamelCase} from "../../../utils/string-utils";
import { isPromise } from "../../../utils/promise-helpers";
import { isNfAjv2019WithContext } from "../nf-ajv";

export const extendedKeywords = [
    "multipleOf",
    "maximum",
    "exclusiveMaximum",
    "minimum",
    "exclusiveMinimum",
    "maxLength",
    "minLength",
    "pattern",
    "maxItems",
    "minItems",
    "uniqueItems",
    "maxProperties",
    "minProperties",
    "required",
    "const",
    "enum",
] as const;

type Kwd = typeof extendedKeywords[number];

const checkEvaluatedToNumber = (value: any) => {
    if (typeof value !== "number" || isNaN(value)) {
        throw new Error("Expression must evaluate to a number");
    }
};

const checkEvaluatedToBoolean = (value: any) => {
    if (typeof value !== "boolean") {
        throw new Error("Expression must evaluate to a boolean");
    }
};

const checkEvaluatedToArray = (value: any) => {
    if (!Array.isArray(value)) {
        throw new Error("Expression must evaluate to an array");
    }
};

type KeywordImplementationFn = (
    schemaValue: any,
    data: any,
    it: SchemaObjCxt,
    parentSchema: AnySchemaObject
) => boolean | Partial<ErrorObject>[];

export const nfMultipleOfKwImpl: KeywordImplementationFn = (schemaValue, data, it) => {
    checkEvaluatedToNumber(schemaValue);
    if (typeof data === "number" && !isNaN(data) && schemaValue !== 0) {
        const res = data / schemaValue;
        const prec = it.opts.multipleOfPrecision;
        const result =
            typeof prec === "number" ? Math.abs(Math.round(res) - res) <= Math.pow(10, -prec) : res === Math.round(res);
        if (result) {
            return true;
        }
    }
    return [
        {
            message: `must be multiple of ${schemaValue}`,
            params: {multipleOf: schemaValue},
        },
    ];
};

export const nfMaximumKwImpl: KeywordImplementationFn = (schemaValue, data) => {
    checkEvaluatedToNumber(schemaValue);
    if (typeof data === "number" && !isNaN(data) && schemaValue >= data) {
        return true;
    }
    return [
        {
            message: `must be <= ${schemaValue}`,
            params: {comparison: "<=", limit: schemaValue},
        },
    ];
};

export const nfExclusiveMaximumKwImpl: KeywordImplementationFn = (schemaValue, data) => {
    checkEvaluatedToNumber(schemaValue);
    if (typeof data === "number" && !isNaN(data) && schemaValue > data) {
        return true;
    }
    return [
        {
            message: `must be < ${schemaValue}`,
            params: {comparison: "<", limit: schemaValue},
        },
    ];
};

export const nfMinimumKwImpl: KeywordImplementationFn = (schemaValue, data) => {
    checkEvaluatedToNumber(schemaValue);
    if (typeof data === "number" && !isNaN(data) && schemaValue <= data) {
        return true;
    }
    return [
        {
            message: `must be >= ${schemaValue}`,
            params: {comparison: ">=", limit: schemaValue},
        },
    ];
};

export const nfExclusiveMinimumKwImpl: KeywordImplementationFn = (schemaValue, data) => {
    checkEvaluatedToNumber(schemaValue);
    if (typeof data === "number" && !isNaN(data) && schemaValue < data) {
        return true;
    }
    return [
        {
            message: `must be > ${schemaValue}`,
            params: {comparison: ">", limit: schemaValue},
        },
    ];
};

export const nfMaxLengthKwImpl: KeywordImplementationFn = (schemaValue, data) => {
    checkEvaluatedToNumber(schemaValue);
    if (typeof data === "string" && ucs2length(data) <= schemaValue) {
        return true;
    }
    return [
        {
            message: `must NOT have more than ${schemaValue} characters`,
            params: {limit: schemaValue},
        },
    ];
};

export const nfMinLengthKwImpl: KeywordImplementationFn = (schemaValue, data) => {
    checkEvaluatedToNumber(schemaValue);
    if (typeof data === "string" && ucs2length(data) >= schemaValue) {
        return true;
    }
    return [
        {
            message: `must NOT have less than ${schemaValue} characters`,
            params: {limit: schemaValue},
        },
    ];
};

export const nfPatternKwImpl: KeywordImplementationFn = (schemaValue, data, it) => {
    let pattern: RegExp | undefined = undefined;
    if (typeof schemaValue === "string") {
        const u = it.opts.unicodeRegExp ? "u" : "";
        pattern = new RegExp(schemaValue, u);
    } else if (schemaValue instanceof RegExp) {
        pattern = schemaValue;
    } else {
        throw new Error("Expression must evaluate to a string or a RegExp object");
    }
    if (typeof data === "string" && pattern.test(data)) {
        return true;
    }
    return [
        {
            message: `must match pattern "${schemaValue}"`,
            params: {pattern: schemaValue},
        },
    ];
};

export const nfMaxItemsKwImpl: KeywordImplementationFn = (schemaValue, data) => {
    checkEvaluatedToNumber(schemaValue);
    if (Array.isArray(data) && data.length <= schemaValue) {
        return true;
    }
    return [
        {
            message: `must NOT have more than ${schemaValue} items`,
            params: {limit: schemaValue},
        },
    ];
};

export const nfMinItemsKwImpl: KeywordImplementationFn = (schemaValue, data) => {
    checkEvaluatedToNumber(schemaValue);
    if (Array.isArray(data) && data.length >= schemaValue) {
        return true;
    }
    return [
        {
            message: `must NOT have less than ${schemaValue} items`,
            params: {limit: schemaValue},
        },
    ];
};

export const nfUniqueItemsKwImpl: KeywordImplementationFn = (schemaValue, data) => {
    checkEvaluatedToBoolean(schemaValue);
    let i = 0;
    let j = 0;
    if (Array.isArray(data) && data.length >= schemaValue) {
        let result = true;
        outer: for (; i < data.length; i++) {
            for (j = i + 1; j < data.length; j++) {
                if (equals(data[i], data[j])) {
                    result = false;
                    break outer;
                }
            }
        }
        if (result) {
            return true;
        }
    }
    return [
        {
            message: `must NOT have duplicate items (items ## ${j} and ${i} are identical)`,
            params: {i, j},
        },
    ];
};

export const nfMaxPropertiesKwImpl: KeywordImplementationFn = (schemaValue, data) => {
    checkEvaluatedToBoolean(schemaValue);
    if (typeof data === "object" && Object.keys(data).length >= schemaValue) {
        return true;
    }
    return [
        {
            message: `must NOT have more than ${schemaValue} items`,
            params: {limit: schemaValue},
        },
    ];
};

export const nfMinPropertiesKwImpl: KeywordImplementationFn = (schemaValue, data) => {
    checkEvaluatedToBoolean(schemaValue);
    if (typeof data === "object" && Object.keys(data).length <= schemaValue) {
        return true;
    }
    return [
        {
            message: `must NOT have less than ${schemaValue} items`,
            params: {limit: schemaValue},
        },
    ];
};

export const nfRequiredKwImpl: KeywordImplementationFn = (schemaValue, data, it, parentSchema) => {
    checkEvaluatedToArray(schemaValue);
    if (typeof data !== "object") {
        return [
            {
                message: `Data must be an object`,
            },
        ];
    }

    let errors: Partial<ErrorObject>[] = [];
    function addMissingProperty(missingProperty: string) {
        errors.push({
            message: `must have required property '${missingProperty}'`,
            params: {missingProperty},
        });
    }
    const ownProps = it.opts.ownProperties;
    for (let prop of schemaValue) {
        if (data[prop] === undefined || (ownProps && !Object.prototype.hasOwnProperty.call(data, prop))) {
            addMissingProperty(prop);
            if (it.allErrors !== true) {
                break;
            }
        }
    }
    if (it.opts.strictRequired) {
        const props = parentSchema.properties;
        for (const requiredKey of schemaValue) {
            if (props?.[requiredKey] === undefined && !it.definedProperties.has(requiredKey)) {
                const schemaPath = it.schemaEnv.baseId + it.errSchemaPath;
                const msg = `required property "${requiredKey}" is not defined at "${schemaPath}" (strictRequired)`;
                checkStrictMode(it, msg, it.opts.strictRequired);
            }
        }
    }
    return errors.length > 0 ? errors : true;
};

export const nfConstKwImpl: KeywordImplementationFn = (schemaValue, data) => {
    if (data === schemaValue) {
        return true;
    }
    return [
        {
            message: "must be equal to constant",
            params: {allowedValue: schemaValue},
        },
    ];
};

export const nfEnumKwImpl: KeywordImplementationFn = (schemaValue, data) => {
    checkEvaluatedToArray(schemaValue);
    if ((schemaValue as any[]).some(s => equals(s, data))) {
        return true;
    }
    return [
        {
            message: "must be equal to one of the allowed values",
            params: {allowedValues: schemaValue},
        },
    ];
};

const defs: {[K in Kwd]: {impl: KeywordImplementationFn}} = {
    multipleOf: {
        impl: nfMultipleOfKwImpl,
    },
    maximum: {
        impl: nfMaximumKwImpl,
    },
    exclusiveMaximum: {
        impl: nfExclusiveMaximumKwImpl,
    },
    minimum: {
        impl: nfMinimumKwImpl,
    },
    exclusiveMinimum: {
        impl: nfExclusiveMinimumKwImpl,
    },
    maxLength: {
        impl: nfMaxLengthKwImpl,
    },
    minLength: {
        impl: nfMinLengthKwImpl,
    },
    pattern: {
        impl: nfPatternKwImpl,
    },
    maxItems: {
        impl: nfMaxItemsKwImpl,
    },
    minItems: {
        impl: nfMinItemsKwImpl,
    },
    uniqueItems: {
        impl: nfUniqueItemsKwImpl,
    },
    maxProperties: {
        impl: nfMaxPropertiesKwImpl,
    },
    minProperties: {
        impl: nfMinPropertiesKwImpl,
    },
    required: {
        impl: nfRequiredKwImpl,
    },
    const: {
        impl: nfConstKwImpl,
    },
    enum: {
        impl: nfEnumKwImpl,
    },
};

export const buildExprKwdFn = (keyword: string, impl: KeywordImplementationFn) => {
    return  (schema: any, parentSchema: AnySchemaObject, it: SchemaObjCxt) => {
        const fn = compileNfExpr(schema, it.errSchemaPath);
        const nfContext = isNfAjv2019WithContext(it.self) ? it.self.nfContext : undefined;
        const validationFn: DataValidateFunction = (data, dataCtx) => {
            if (!dataCtx) {
                return false;
            }
            let value = fn(data, {
                ...dataCtx,
                schema,
                parentSchema,
                rootSchema: it.schema,
                nfContext,
            });
            if (isPromise(value)) {
                throw new Error(`Expression cannot evaluate to a Promise (at ${it.errSchemaPath})`);
            }
            try {
                const result = impl(value, data, it, parentSchema);
                if (typeof result === "boolean") {
                    return result;
                }
                validationFn.errors = result.map(s => ({
                    keyword,
                    instancePath: dataCtx?.instancePath ?? "",
                    schemaPath: it.schemaPath.toString(),
                    ...s
                }));
            } catch (e: any) {
                validationFn.errors = [{
                    keyword,
                    instancePath: dataCtx?.instancePath ?? "",
                    schemaPath: it.schemaPath.toString(),
                    params: {},
                }];
            }
            return false;
        };
        return validationFn;
    };
}

export const extendedKeywordDef: FuncKeywordDefinition & AddedKeywordDefinition = {
    keyword: [...extendedKeywords],
    type: [],
    schemaType: ["object"],
    compile: (schema: any, parentSchema: AnySchemaObject, it: SchemaObjCxt) => {
        const keyword = Object.entries(parentSchema).find(s => s[1] === schema)?.[0] as Kwd | undefined;
        if (!keyword) {
            throw new Error("Cannot find schema entry");
        }
        if (!extendedKeywords.includes(keyword)) {
            throw new Error(`Keyword ${keyword} is not one of ${extendedKeywords}`);
        }
        const implFn = buildExprKwdFn(keyword, defs[keyword].impl);
        return implFn(schema, parentSchema, it);
    },
};

const toNfKeyword = (keyword: Kwd): string => {
    return `nf${toUpperCamelCase(keyword)}`;
};

const nfKeywordMap = mapObjIndexed((s: Kwd[]) => s[0], groupBy(toNfKeyword, extendedKeywords));
export const nfKeywords = extendedKeywords.map(toNfKeyword);

export const nfExprKeywordDef: FuncKeywordDefinition = {
    keyword: nfKeywords,
    schemaType: "object",
    compile: (schema: any, parentSchema: AnySchemaObject, it: SchemaObjCxt) => {
        const keyword = Object.entries(parentSchema).find(s => s[1] === schema)?.[0] as string | undefined;
        if (!keyword) {
            throw new Error("Cannot find schema entry");
        }
        const baseKwd = nfKeywordMap[keyword];
        if (!baseKwd) {
            throw new Error(`Keyword ${keyword} is not one of ${nfKeywords}`);
        }
        // Report errors as base keyword (so that dependent packages like jsonforms can interpret them correctly)
        const implFn = buildExprKwdFn(baseKwd, defs[baseKwd].impl);
        return implFn(schema, parentSchema, it);
    },
};
