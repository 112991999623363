import {FuncKeywordDefinition} from "ajv";
import {isPresent} from "ts-is-present";

import {isPromise} from "../../../utils/promise-helpers";
import {compileNfExpr} from "../exprs";
import {isNfAjv2019WithContext} from "../nf-ajv";

const def: FuncKeywordDefinition = {
    keyword: "nfCalculatedValue",
    schemaType: "object",
    modifying: true,
    compile: (schema, parentSchema, it) => {
        if (!isPresent(parentSchema.default)) {
            throw new Error(`Schema using nfCalculatedValue must define a default value (at ${it.errSchemaPath})`);
        }
        const fn = compileNfExpr(schema, it.errSchemaPath);
        const nfContext = isNfAjv2019WithContext(it.self) ? it.self.nfContext : undefined;
        return (data, ctx) => {
            if (!ctx) {
                return false;
            }
            let value = fn(data, {
                ...ctx,
                schema,
                parentSchema,
                rootSchema: it.schema,
                nfContext,
            });
            if (isPromise(value)) {
                throw new Error(`Expression cannot evaluate to a Promise (at ${it.errSchemaPath})`);
            }
            const {parentData, parentDataProperty} = ctx;
            parentData[parentDataProperty] = value;
            return true;
        };
    },
};

export default def;
