import {ErrorObject, FuncKeywordDefinition} from "ajv";
import {DataValidateFunction} from "ajv/dist/types";

import {compileNfExpr} from "../exprs";
import {isNfAjv2019WithContext} from "../nf-ajv";

const def: FuncKeywordDefinition = {
    keyword: "nfValidate",
    schemaType: ["object", "array"],
    compile: (schema, parentSchema, it) => {
        let schemas = Array.isArray(schema) ? schema : [schema];
        const fns = schemas.map((s, i) => {
            let path = it.errSchemaPath;
            if (i > 0 || Array.isArray(schema)) {
                path += `/${i}`;
            }
            return compileNfExpr(s, path);
        });
        const nfContext = isNfAjv2019WithContext(it.self) ? it.self.nfContext : undefined;
        const validationFn: DataValidateFunction = (data, ctx) => {
            if (!ctx) {
                return false;
            }
            let result = true;
            const validationErrors: Partial<ErrorObject>[] = [];
            fns.forEach((fn, i) => {
                const schema = schemas[i];
                const value = fn(data, {
                    ...ctx,
                    schema,
                    parentSchema,
                    rootSchema: it.schema,
                    nfContext,
                });
                if (typeof value !== "boolean") {
                    let path = it.errSchemaPath;
                    if (i > 0 || Array.isArray(schema)) {
                        path += `/${i}`;
                    }
                    throw new Error(`Expression as ${path} must evaluate to a boolean value`);
                }
                if (!value && typeof schema.nfMessage === "string") {
                    let instancePath = "";
                    if (typeof schema.nfInstancePath === "string") {
                        instancePath = schema.nfInstancePath.trim();
                    }
                    if (!instancePath.startsWith("#")) {
                        const prefix = ctx?.instancePath?.trim();
                        if (prefix) {
                            instancePath = prefix + instancePath;
                        }
                    } else {
                        instancePath = instancePath.substring(1);
                    }
                    validationErrors.push({
                        keyword: "nfValidate",
                        instancePath,
                        schemaPath: it.schemaPath.toString(),
                        message: schema.nfMessage,
                    });
                }
                result &&= value;
            });
            if (validationErrors.length > 0) {
                validationFn.errors = validationErrors;
            }
            return result;
        };
        return validationFn;
    },
};

export default def;
