import {NfJsonSchema} from "../../models/nf-json-schema";

export interface NfExprContext<T extends string | number = string | number> {
    instancePath: string;
    parentData: {
        [K in T]: any;
    };
    parentDataProperty: T;
    rootData: Record<string, any> | any[];
    schema: NfJsonSchema;
    parentSchema: NfJsonSchema;
    rootSchema: NfJsonSchema;
    nfContext?: Record<string, any>;
}

export type CompiledNfExprFn = (data: any, ctx: NfExprContext) => any | Promise<any>;

export const compileNfExpr = (schema: NfJsonSchema, schemaPath?: string): CompiledNfExprFn => {
    const expr = schema.nfExpr;
    if (!expr) {
        throw new Error(`nfExpr property expected at ${schemaPath}`);
    }
    // eslint-disable-next-line no-new-func
    const fn = new Function("data", "ctx", `return ${expr}`);
    return fn as CompiledNfExprFn;
};
