import * as yup from "yup";
import i18next from "i18next";
import {uniq, mergeDeepLeft} from "ramda";
import {isPresent} from "ts-is-present";
import {ValidateOptions, Message} from "yup/lib/types";
import {ErrorObject} from "ajv";

import {isValidationFailure, validate} from "./ajv";
import {ErrorInfo} from "./../models/error-info";

/*eslint-disable no-new-func*/

//TODO: Evaluar migración a https://github.com/vriad/zod

const yupLocaleTemplate = {
    mixed: {
        default: "",
        required: "",
        oneOf: "",
        notOneOf: "",
        notType: "",
        //"defined": ""
    },
    string: {
        length: "",
        min: "",
        max: "",
        matches: "",
        email: "",
        url: "",
        //"uuid": "",
        trim: "",
        lowercase: "",
        uppercase: "",
    },
    number: {
        min: "",
        max: "",
        lessThan: "",
        moreThan: "",
        //"notEqual": "",
        positive: "",
        negative: "",
        integer: "",
    },
    date: {
        min: "",
        max: "",
    },
    object: {
        noUnknown: "",
    },
    array: {
        min: "",
        max: "",
    },
};

const buildLocale = (locale: string, obj: any, path: string[]): any => {
    const result: any = {};
    for (const [key, value] of Object.entries(obj)) {
        if (typeof value === "object") {
            path.push(key);
            result[key] = buildLocale(locale, value, path);
            path.pop();
        } else {
            result[key] = i18next.t(`${path.join(".")}.${key}`);
        }
    }
    return result;
};

const setYupLocale = (locale: string) => {
    const result = buildLocale(locale, yupLocaleTemplate, ["Yup"]);
    yup.setLocale(result);
};

const addArrayUniquenessValidators = () => {
    yup.addMethod<yup.ArraySchema<any>>(yup.array, "unique", function (message: Message<{duplicates: any[]}>) {
        return this.test("unique", "", function (value: any) {
            const list = value as any[];
            if (!list || list.length === 0) {
                return true;
            }
            const dups: any[] = [];
            list.forEach((item, i) => {
                for (let j = i + 1; j < list.length; j++) {
                    if (item === list[j]) {
                        dups.push(item);
                    }
                }
            });
            if (dups.length > 0) {
                return this.createError({path: this.path, message, params: {duplicates: uniq(dups)}});
            }
            return true;
        });
    });
    yup.addMethod<yup.ArraySchema<any>>(
        yup.array,
        "uniqueBy",
        function (fn: (value: any) => any, message: Message<{duplicates: any[]}>) {
            return this.test("uniqueBy", "", function (value: any) {
                const list = value as any[];
                if (!list || list.length === 0) {
                    return true;
                }
                const dups: any[] = [];
                list.forEach((item, i) => {
                    const itemValue = fn(item);
                    for (let j = i + 1; j < list.length; j++) {
                        const testValue = fn(list[j]);
                        if (itemValue === testValue) {
                            dups.push(itemValue);
                        }
                    }
                });
                if (dups.length > 0) {
                    return this.createError({path: this.path, message, params: {duplicates: uniq(dups)}});
                }
                return true;
            });
        }
    );
};

const addAtLeastOneRequired = () => {
    yup.addMethod<yup.ObjectSchema<any>>(
        yup.object,
        "atLeastOneRequired",
        function (fieldNames: string[], message?: Message) {
            message = message ?? i18next.t("AtLeastOneRequired", {fieldNames: fieldNames.join(", ")}) ?? "";
            return this.test("atLeastOneRequired", message, function (obj: any) {
                if (!fieldNames || fieldNames.length === 0) {
                    return true;
                }
                if (!isPresent(obj)) {
                    return false;
                }
                if (fieldNames.some(s => isPresent(obj[s]))) {
                    return true;
                }
                return this.createError({
                    path: this.path ? `${this.path}.${fieldNames[0]}` : fieldNames[0],
                    message: i18next.t("AtLeastOneRequired", {fieldNames: fieldNames.join(", ")}),
                });
            });
        }
    );
};

const addWithLocalContext = () => {
    yup.addMethod<yup.BaseSchema<any>>(
        yup.mixed,
        "withLocalContext",
        function (fn: (schema: yup.BaseSchema<any>) => yup.BaseSchema<any>) {
            return fn(this);
        }
    );
};

const mapAjvErrors = (errors: ErrorObject[], basePath?: string): ErrorInfo[] => {
    return errors.map(e => {
        const params = Object.entries(e.params)
            .map(([key, value]) => `${key}: ${value}`)
            .join("; ");
        return {
            dataPath: (basePath ?? "") + (e.instancePath ?? ""),
            message: `${e.message} (${params})`,
        };
    });
};

const addJsonValidators = () => {
    const validateSchema = async (json: any, jsonSchemaContextKey: string, context: yup.TestContext) => {
        let jsonSchema = (context.options.context as any)?.[jsonSchemaContextKey];
        if (typeof jsonSchema === "function") {
            jsonSchema = jsonSchema();
        }
        if (typeof jsonSchema === "string") {
            jsonSchema = JSON.parse(jsonSchema);
        }

        if (jsonSchema) {
            const result = validate(jsonSchema, json);
            if (isValidationFailure(result)) {
                const einfos = mapAjvErrors(result.errors);
                if (einfos.length > 0) {
                    const errors = einfos.map(e => {
                        return context.createError({
                            path: context.path,
                            message: e.dataPath + " - " + e.message,
                        });
                    });
                    const aggregate = context.createError();
                    aggregate.inner = errors;
                    return aggregate;
                }
            }
        }
        return true;
    };

    yup.addMethod<yup.ObjectSchema<any>>(yup.object, "json", function (jsonSchemaContextKey: string) {
        return this.test({
            test: async function (value) {
                if (typeof value === "object") {
                    return await validateSchema(value, jsonSchemaContextKey, this);
                }
                return true;
            },
        });
    });

    yup.addMethod<yup.StringSchema>(yup.string, "json", function (jsonSchemaContextKey: string) {
        return this.test({
            test: async function (value) {
                if (typeof value === "string" && value) {
                    let json: any;
                    try {
                        json = JSON.parse(value);
                    } catch (error: any) {
                        return this.createError({
                            path: this.path,
                            message: error.toString(),
                        });
                    }
                    return await validateSchema(json, jsonSchemaContextKey, this);
                }
                return true;
            },
        });
    });
};

const iso8601Regex =
    /^(-?(?:[1-9][0-9]*)?[0-9]{4})-(1[0-2]|0[1-9])-(3[01]|0[1-9]|[12][0-9])T(2[0-3]|[01][0-9]):([0-5][0-9]):([0-5][0-9])(.[0-9]+)?(Z|[+-][01][0-9]:[0-9][0-9])?$/;

const addIso8601FormattedString = () => {
    yup.addMethod<yup.StringSchema>(yup.string, "iso8601Formatted", function (message?: Message<{value: string}>) {
        return this.test("iso8601Formatted", "", function (val: any) {
            const value = val as string;
            if (!value) {
                return true;
            }
            if (!iso8601Regex.test(value)) {
                const defaultedMsg = (message =
                    message ?? i18next.t("ValueDoesNotComplyWithIso8601DateTimeStandard", {value}) ?? "");
                return this.createError({path: this.path, message: defaultedMsg});
            }
            return true;
        });
    });
};

const identifierRegex = /^[a-zA-Z_][a-zA-Z0-9_-]*$/;

const addIdentifier = () => {
    yup.addMethod<yup.StringSchema>(yup.string, "identifier", function (message?: Message<{value: string}>) {
        return this.test("identifier", "", function (val: any) {
            const value = val as string;
            if (!value) {
                return true;
            }
            if (!identifierRegex.test(value)) {
                const defaultedMsg = message ?? i18next.t("ValueIsNotAnIdentifier", {value}) ?? "";
                return this.createError({path: this.path, message: defaultedMsg});
            }
            return true;
        });
    });
};

const addYupErrors = (errors: yup.ValidationError[], errorInfos: ErrorInfo[], basePath?: string) => {
    for (const e of errors) {
        if (e.inner && e.inner.length > 0) {
            addYupErrors(e.inner, errorInfos, basePath);
        } else {
            errorInfos.push({dataPath: (basePath ?? "") + (e.path ?? ""), message: e.message});
        }
        /*if (e.inner && e.inner.length > 0) {
            addYupErrors(e.inner, messages, basePath);
        }*/
    }
};

export const extractYupErrorMessages = (
    errors: yup.ValidationError | yup.ValidationError[],
    basePath?: string
): ErrorInfo[] => {
    const result: ErrorInfo[] = [];
    errors = Array.isArray(errors) ? errors : [errors];
    addYupErrors(errors, result, basePath);
    return result;
};

export const validateWithYupSchema = async (
    schema: yup.BaseSchema<any> | undefined,
    value: any,
    options?: ValidateOptions<any>
): Promise<ErrorInfo[]> => {
    if (!schema) {
        return [] as ErrorInfo[];
    }
    const result = await schema
        .validate(value, mergeDeepLeft(options ?? {}, {abortEarly: false, strict: true}))
        .then(() => [] as ErrorInfo[])
        .catch((e: yup.ValidationError) => {
            return extractYupErrorMessages(e);
        });
    return result;
};

export const setupYup = () => {
    setYupLocale(i18next.language);
    i18next.on("languageChanged", s => setYupLocale(s));
    addArrayUniquenessValidators();
    addAtLeastOneRequired();
    addJsonValidators();
    addWithLocalContext();
    addIso8601FormattedString();
    addIdentifier();
};
