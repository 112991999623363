import env from "@beam-australia/react-env";

export const publicUrl = process.env.PUBLIC_URL;

const _businessDirectoryServerUrl = env("NIMFLOW_BUSINESS_DIRECTORY_SERVER_URL");

if (!_businessDirectoryServerUrl) {
    throw new Error("Environment variable REACT_APP_NIMFLOW_BUSINESS_DIRECTORY_SERVER_URL not configured");
}

export const businessDirectoryServerUrl = _businessDirectoryServerUrl; 

export const auth0Domain = env("AUTH0_DOMAIN");
export const auth0ClientId = env("AUTH0_CLIENT_ID");
export const auth0Audience = env("AUTH0_AUDIENCE");

export const persistedConfigurationStorageKey = env("PERSISTENT_CONFIG_KEY") ?? "nimflowPortalConfiguration";

export const debugJsonForms = env("DEBUG_JSONFORMS") === "true";