import {Vocabulary} from "ajv";

import {nfExprKeywordDef} from "./keyword-extensions";
import markdownDescription from "./markdown-description";
import nfCalculatedValue from "./nf-calculated-value";
import nfData from "./nf-data";
import nfDisabled from "./nf-disabled";
import nfHidden from "./nf-hidden";
import nfValidate from "./nf-validate";

const nfVocabulary: Vocabulary = [
    markdownDescription,
    nfCalculatedValue,
    nfValidate,
    nfExprKeywordDef,
    nfData,
    nfHidden,
    nfDisabled,
];

export default nfVocabulary;
