import Ajv, {JSONSchemaType, KeywordCxt, Options} from "ajv";
import {funcKeywordCode} from "ajv/dist/compile/validate/keyword";
import Ajv2019 from "ajv/dist/2019";
import {
    AddedKeywordDefinition,
    AnySchema,
    AnyValidateFunction,
    AsyncSchema,
    AsyncValidateFunction,
    CodeKeywordDefinition,
    Schema,
    ValidateFunction,
} from "ajv/dist/types";
import {JTDDataType, JTDSchemaType, SomeJTDSchemaType} from "ajv/dist/types/jtd-schema";

import nfVocabulary from "./nf-vocabulary";
import {extendedKeywordDef as extendedKeyword, extendedKeywords} from "./nf-vocabulary/keyword-extensions";
import nfJsonSchema from "../../schemas/nf-json-schema.json";

const isCodeKwd = (kwdef: AddedKeywordDefinition): kwdef is AddedKeywordDefinition & CodeKeywordDefinition => {
    return "code" in kwdef;
};

const extendKeywordsToNfExpr = (kwdef: AddedKeywordDefinition & CodeKeywordDefinition) => {
    let type = kwdef.schemaType;
    /*if (!Array.isArray(type)) {
        type = !!type ? [type] : [];
    }*/
    if (type.length > 0 && !type.includes("object")) {
        type.push("object");
        kwdef.schemaType = type;
    }
    const originalCode = kwdef.code;
    kwdef.code = (ctx, ruleType) => {
        const {schema, keyword} = ctx;
        if (typeof schema !== "object" || !("nfExpr" in schema)) {
            return originalCode(ctx, ruleType);
        }
        const ctx2 = new KeywordCxt(ctx.it, extendedKeyword, keyword);
        funcKeywordCode(ctx2, extendedKeyword);
    };
};

const isNfAjv2019WithContextKey = Symbol("isNfAjv2019WithContext");

export class NfAjv2019 extends Ajv2019 {
    constructor(opts: Options = {}) {
        super({
            ...opts,
            useDefaults: true,
        });
    }

    _addVocabularies(): void {
        super._addVocabularies();
        this.addVocabulary(nfVocabulary);

        for (const kw of extendedKeywords) {
            const kwdef = this.getKeyword(kw);
            if (typeof kwdef !== "boolean" && isCodeKwd(kwdef)) {
                extendKeywordsToNfExpr(kwdef);
            }
        }
    }

    _addDefaultMetaSchema(): void {
        super._addDefaultMetaSchema();
        const {meta} = this.opts;
        if (!meta) return;
        this.addMetaSchema(nfJsonSchema);
        this.refs["http://json-schema.org/schema"] = nfJsonSchema.$id;
    }

    // Create validation function for passed schema
    // _meta: true if schema is a meta-schema. Used internally to compile meta schemas of user-defined keywords.
    compile<T = unknown>(schema: Schema | JSONSchemaType<T>, _meta?: boolean): ValidateFunction<T>;
    // Separated for type inference to work
    // eslint-disable-next-line @typescript-eslint/unified-signatures
    compile<T = unknown>(schema: JTDSchemaType<T>, _meta?: boolean): ValidateFunction<T>;
    // This overload is only intended for typescript inference, the first
    // argument prevents manual type annotation from matching this overload
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    compile<N extends never, T extends SomeJTDSchemaType>(schema: T, _meta?: boolean): ValidateFunction<JTDDataType<T>>;
    compile<T = unknown>(schema: AsyncSchema, _meta?: boolean): AsyncValidateFunction<T>;
    compile<T = unknown>(schema: AnySchema, _meta?: boolean): AnyValidateFunction<T>;
    compile<T = unknown>(schema: AnySchema, _meta?: boolean): AnyValidateFunction<T> {
        const fn = super.compile(schema, _meta);
        // const validate: any = (...params: Parameters<ValidateFunction<T>>) => {
        //     console.log("Prevalidate", params[0]);
        //     const result = fn(...params) as any;
        //     console.log("Postvalidate", params[0]);
        //     validate.errors = fn.errors;
        //     return result
        // };
        // if ("errors" in fn) {
        //     validate.errors = fn.errors;
        // }
        // validate.schema = fn.schema;
        // validate.schemaEnv = fn.schemaEnv;
        // if ("async" in fn) {
        //     (validate as AsyncValidateFunction).$async = (fn as AsyncValidateFunction).$async;
        // }
        // if ("source" in fn) {
        //     validate.source = fn.source;
        // }
        // if ("evaluated" in fn) {
        //     validate.evaluated = fn.evaluated;
        // }
        // return validate as AnyValidateFunction<T>;
        return fn as AnyValidateFunction<T>;
    }

    withContext(nfContext: Record<string, any>) {
        const proxy = new Proxy(this, {
            get: (target, key, receiver) => {
                if (key === isNfAjv2019WithContextKey) {
                    return true;
                }
                if (key === "nfContext") {
                    return nfContext;
                }
                let value = Reflect.get(target, key, receiver);
                return value;
            }
        });
        return proxy;
    }
}


export interface NfAjv2019WithContext extends NfAjv2019 {
    nfContext: Record<string, any>;
}

export const isNfAjv2019WithContext = (ajv: Ajv): ajv is NfAjv2019WithContext => {
    return (ajv as any)[isNfAjv2019WithContextKey] === true;
}

